import * as React from 'react'
import * as css from './CTABlock.module.css'
import SanityBlock from 'components/common/SanityBlock'
import CTAList from 'components/common/CTAList'
import Img from 'components/Img'

const CTABlock = ({ _rawContent, ctas, bgColor, image }) => {
	return (
		<section
			className="container section-padding side-padding"
		>
			<div
				className={css.layout}
				style={{ backgroundColor: bgColor }}
			>
				<Img className={css.img} image={image} />

				<div className={css.txt}>
					<SanityBlock body={_rawContent} />
					<CTAList className={css.ctas} ctas={ctas} />
				</div>
			</div>
		</section>
	)
}

export default CTABlock
