import * as React from 'react'

import BlogListFeatured from 'components/modules/BlogListFeatured'
import BlogList from 'components/modules/BlogList'
import CTABlock from 'components/modules/CTABlock'
import Hero from 'components/modules/Hero'

const Modules = ({ modules }) => {
	return <>
		{modules?.map((module, key) => {
			switch (module._type) {
				case 'blog-list': return <BlogList {...module} key={key} />
				case 'blog-list.featured': return <BlogListFeatured {...module} key={key} />
				case 'cta-block': return <CTABlock {...module} key={key} />
				case 'hero': return <Hero {...module} key={key} />
				default: return <div data-type={module._type} key={key} />
			}
		})}
	</>
}

export default Modules
