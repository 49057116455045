import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SanityBlock from 'components/common/SanityBlock'
import PostPreview from 'components/common/PostPreview'
import * as css from './BlogListFeatured.module.css'

const BlogListFeatured = ({ _rawContent }) => {
	const posts = useStaticQuery(query).allSanityBlogPost.nodes
	const [first, ...rest] = posts

	return (
		<section className="container section-padding side-padding">
			<header>
				<SanityBlock body={_rawContent} />
			</header>

			<div className={css.layout}>
				<PostPreview post={first} />

				<div class={css.rest}>
					{rest?.map((post, key) => (
						<PostPreview small post={post} key={key} />
					))}
				</div>
			</div>
		</section>
	)
}

export default BlogListFeatured

const query = graphql`query BlogListFeatured {
	allSanityBlogPost(
		sort: {fields: publishDate, order: ASC}
		filter: {featured: {eq: true}}
		limit: 4
	) {
		nodes {
			title
			body { children { text } }
			publishDate
			formattedPublishDate: publishDate(formatString: "MMMM D, yyyy")
			featuredImage {
				asset {
					gatsbyImageData(width: 550, aspectRatio: 1.5)
					url
				}
			}
			category { title }
			seo {
				description
				slug { current }
			}
		}
	}
}`
