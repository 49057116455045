import * as React from 'react'
import * as css from './Hero.module.css'
import SanityBlock from 'components/common/SanityBlock'
import Img from 'components/Img'
import clsx from 'clsx'

const Hero = ({ _rawContent, image }) => {
	return (
		<section className={css.root}>
			<Img
				className={css.img}
				image={image}
			/>

			<div className={clsx(css.inner, 'container text-center')}>
				<SanityBlock body={_rawContent} />
			</div>
		</section>
	)
}

export default Hero
