import * as React from 'react'
import * as css from './BlogList.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import SanityBlock from 'components/common/SanityBlock'
import PostPreview from 'components/common/PostPreview'
import clsx from 'clsx'

const BlogList = ({ _rawContent }) => {
	const { nodes: posts } = useStaticQuery(query).allSanityBlogPost
	const [$limit, set$limit] = React.useState(6)

	return (
		<section className={clsx(css.root, 'container section-padding side-padding')}>
			<header>
				<SanityBlock body={_rawContent} />
			</header>

			<ul className={css.list}>
				{posts?.map((post, key) => (key < $limit) && (
					<li key={key}>
						<PostPreview post={post} />
					</li>
				))}
			</ul>

			{$limit < posts?.length && (
				<div className="text-center">
					<button className="action" onClick={() => set$limit($limit + 6)}>
						Load more +
					</button>
				</div>
			)}
		</section>
	)
}

export default BlogList

const query = graphql`query BlogList {
	allSanityBlogPost(
		sort: {fields: publishDate, order: DESC}
		filter: {featured: {ne: true}}
		limit: 100
	) {
		nodes {
			title
			body { children { text } }
			publishDate
			formattedPublishDate: publishDate(formatString: "MMMM D, yyyy")
			featuredImage {
				asset {
					gatsbyImageData(width: 550, aspectRatio: 1.5)
					url
				}
			}
			category { title }
			seo {
				description
				slug { current }
			}
		}
	}
}`
