import * as React from 'react'
import * as css from './CTAList.module.css'
import Cta from 'components/common/CTA'
import clsx from 'clsx'

const CTAList = ({ ctas, className }) => {
	if (!ctas?.length) return null

	return (
		<p className={clsx(css.root, className)}>
			{ctas.map((cta, key) => (
				<Cta {...cta} key={key} />
			))}
		</p>
	)
}

export default CTAList
