import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Modules from 'components/modules/Modules'
import StructuredData from 'components/StructuredData'

const HomePg = ({ location }) => {
	const { pg } = useStaticQuery(query)

	return (
		<DefaultLayout {...pg.seo}>
			<Modules modules={pg.modules} />
			<StructuredData />
		</DefaultLayout>
	)
}

export default HomePg

const query = graphql`query HomePg {
	pg: sanityHomepage {
		modules {
			...blogList
			...blogListFeatured
			...ctaBlock
			...hero
		}
		seo {
			title
			description
		}
	}
}`
