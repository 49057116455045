import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const StructuredData = () => {
	const { site } = useStaticQuery(query)

	const data = {
		"@context": "http://schema.org",
		"@type": "WebSite",
		"url": site.url,
		"potentialAction": {
			"@type": "SearchAction",
			"target": `${ site.url }/search?query={search_term_string}`,
			"query-input": "required name=search_term_string",
		}
	}

	return (
		<script
			type="application/ld+json"
			dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
		/>
	)
}

export default StructuredData

const query = graphql`query StructuredData {
	site: sanitySite {
		url
	}
}`
